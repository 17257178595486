import React from "react";
import { range } from "lodash";
import PropTypes from "prop-types";
import { StarIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";

import { useUser } from "../../contexts/user";

export default function ProgressBar({
  current,
  max,
  limit,
  animateOnMount = false,
}) {
  const [activePill, setActivePill] = React.useState();
  const { user } = useUser();
  const cap = limit || max;
  const currentUnderCap = Math.min(current, cap);
  const numberOfPills = user?.isLoggedIn ? cap + 2 : cap + 1;
  const activePillOffset =
    activePill &&
    typeof activePill.offsetLeft === "number" &&
    typeof activePill.offsetWidth === "number"
      ? activePill.offsetLeft + activePill.offsetWidth
      : 0;

  const canAnimateOnMountRef = React.useRef(animateOnMount);

  React.useEffect(() => {
    // Allow mount animation to happen only when moving from 0 to 1
    if (current === 0) {
      canAnimateOnMountRef.current = true;
    }
  }, [current]);

  return (
    <div className="w-full flex relative">
      {range(1, numberOfPills).map((point) =>
        point === max + 1 ? (
          <div
            className="hidden lg:flex  items-center p-3 z-10 max-sm:px-2"
            key={point}
          >
            <div
              className={twMerge(
                "rounded-full flex items-center justify-center w-2 h-2 sm:w-4 sm:h-4 lg:w-auto lg:h-auto transition-colors delay-500 duration-1000",
                point === currentUnderCap ? "bg-white" : "bg-purple",
              )}
            >
              <div
                className={twMerge(
                  "border border-solid rounded-full flex flex-row justify-center items-center gn-text-sm font-bold px-3 py-2 text-center max-lg:hidden transition-colors whitespace-nowrap delay-500 duration-1000",
                  point === currentUnderCap ? "text-purple" : "text-white",
                )}
              >
                <StarIcon className=" w-4 h-4 text-yellow-400 mr-1" />
                {`${currentUnderCap}/${cap}`}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex items-center flex-1 justify-end p-3 z-10 max-sm:px-2"
            key={point}
            ref={(node) => {
              if (point === currentUnderCap) {
                setActivePill(node);
              }
              return node;
            }}
          >
            <div
              className={twMerge(
                "rounded-full relative w-2 h-2 sm:w-3 sm:h-3 lg:w-6 lg:h-6 transition-colors delay-500 duration-500",
                point <= currentUnderCap ? "bg-white" : "bg-purple",
              )}
            >
              <div
                className={twMerge(
                  "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2",
                  "transition-all delay-500 duration-500 scale-0",
                  point === currentUnderCap && "scale-100 bg-white",
                  "w-5 h-5 sm:w-6 sm:h-6 lg:w-9 lg:h-9",
                  "rounded-full",
                  "flex items-center justify-center",
                )}
              >
                {point === currentUnderCap && (
                  <p className="text-purple font-bold text-center text-sm lg:text-lg">
                    {currentUnderCap}
                  </p>
                )}
              </div>
            </div>
          </div>
        ),
      )}

      {activePillOffset || canAnimateOnMountRef.current ? (
        <motion.div
          initial={canAnimateOnMountRef.current}
          animate={{
            width: currentUnderCap === cap ? "100%" : `${activePillOffset}px`,
          }}
          transition={{ duration: 1, delay: 0.5 }}
          className={twMerge(
            `absolute top-0 bottom-0 left-0 bg-gradient-to-b from-purple-400 to-purple transition-colors delay-500 duration-1000 rounded-full`,
            currentUnderCap === cap &&
              "shadow-purple shadow-[0px_0px_22px_3px_var(--tw-shadow-color)]",
          )}
          style={{
            "--progress": `${(currentUnderCap / cap) * 100}%`, // used at profiles page.
          }}
        />
      ) : null}

      <div className="absolute inset-0 bg-purple opacity-30 rounded-full" />
    </div>
  );
}

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  limit: PropTypes.number,
  animateOnMount: PropTypes.bool,
};
