import React from "react";
import PropTypes from "prop-types";

import Button from "../Button";

function BlogCard({ title, description, imageUrl, onClick }) {
  return (
    <div className="flex flex-col rounded bg-gray-800 overflow-hidden shadow-darkSM group-hover:shadow-darkLG">
      <BlogCard.Cover src={imageUrl} alt={title} />
      <BlogCard.Content>
        <div className="flex-1">
          <BlogCard.Title>{title}</BlogCard.Title>
          <BlogCard.Description>{description}</BlogCard.Description>
        </div>
      </BlogCard.Content>
      <BlogCard.Actions>
        <Button variant="light-outline" size="md" onPress={onClick}>
          Read Post
        </Button>
      </BlogCard.Actions>
    </div>
  );
}

function Cover({ src, alt }) {
  return (
    <div className="flex-shrink-0">
      <img className="w-full h-3xl object-cover" src={src} alt={alt} />
    </div>
  );
}

function Content({ children }) {
  return (
    <div
      className="flex-1 flex flex-col text-white
      px-md md:px-lg pt-md md:pt-lg justify-between items-start"
    >
      {children}
    </div>
  );
}

function Actions({ children }) {
  return (
    <div className="flex-1 flex flex-row text-white px-md md:px-lg pb-md md:pb-lg justify-between items-end">
      {children}
    </div>
  );
}
function Title({ children }) {
  return <p className="gn-headline-sm mb-sm">{children}</p>;
}
function Description({ children }) {
  return <p className="gn-text mb-sm line-clamp-4">{children}</p>;
}

BlogCard.Cover = Cover;
BlogCard.Content = Content;
BlogCard.Title = Title;
BlogCard.Description = Description;
BlogCard.Actions = Actions;

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onClick: PropTypes.func,
};
BlogCard.defaultProps = {
  onClick: () => {},
};
Cover.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      "regular@1x": PropTypes.string,
      "regular@2x": PropTypes.string,
      "regular@3x": PropTypes.string,
    }),
  ]).isRequired,
  alt: PropTypes.string,
};
Cover.defaultProps = {
  alt: "blog card image",
};
Content.propTypes = {
  children: PropTypes.node.isRequired,
};

Actions.propTypes = {
  children: PropTypes.node.isRequired,
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
};
Description.propTypes = {
  children: PropTypes.string.isRequired,
};

export default BlogCard;
