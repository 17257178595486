import React from "react";
import { useLocalstorageState } from "rooks";

import { useAppConfig } from "../contexts/appConfig";

const TRANSIENT_LOCAL_STORAGE_KEY = "TRANSIENT_LOCAL_STORAGE";

export default function useTransientLocalStorage() {
  const [storage, setStorage, clear] = useLocalstorageState(
    TRANSIENT_LOCAL_STORAGE_KEY,
    {},
  );
  const { appConfig } = useAppConfig();

  const setItem = (key, value) => {
    setStorage({ ...storage, [key]: value });
  };

  const getItem = (key) => storage[key];

  const removeItem = (key) => {
    const { [key]: removed, ...newStorage } = storage;
    setStorage(newStorage);
  };

  React.useEffect(() => {
    const handleUnload = () => {
      // Clear the storage if it's the last tab or if there are no tabs
      if (appConfig.tabCount === 1 || appConfig.tabCount === 0) {
        clear();
      }
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, [appConfig.tabCount, clear]);

  return {
    setItem,
    getItem,
    removeItem,
  };
}
