import React from "react";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";

import Button from "../Button";
import TextField from "../TextField";
import TermsOfServiceLink from "../TermsOfServiceLink";
import PrivacyPolicyLink from "../PrivacyPolicyLink";

export default function Newsletter({ handleSubmit }) {
  const [checkboxChecked, setCheckboxChecked] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const handleFormSubmit = () => {
    // This function runs if the component is rendered at the blog app. Because there is no user type at the blog, we send the user type of Anonymous.
    if (!handleSubmit) {
      const newsletterFormData = new FormData();
      newsletterFormData.append("EMAIL", email);
      newsletterFormData.append("USER_TYPE", "Anonymous");
      newsletterFormData.append("ENVIRONMENT", process.env.DEPLOY_ENV);

      fetch(
        "https://6b385459.sibforms.com/serve/MUIEAARRWRv5AhpubfRPS9bT4592MrF6mgSeXCuqMWJ5Lz7bdmolUQYw1WX7b5WgWPA5MEF9x_PygZ-tB8U0c7yi6WuQJFVCtek0NfRxS1CZs1k7ew91AEe16AvTEjfkCB8neDRSNtDo6B2GrL4LoNX0sn0ROnonTJZuHPYtR07Np3YEUjQbWIic7bRjtDsjyhgfA5CotcTeByke",
        {
          method: "post",
          body: newsletterFormData,
        },
      ).catch(() => {});
    }

    if (handleSubmit) {
      handleSubmit({ email, receiveEmails: true });
    }

    setCheckboxChecked(false);
    setEmail("");
    window.open("/company/confirm-newsletter-subscription", "_blank");
  };

  return (
    <div className="container">
      <div className="relative mb-lg bg-gray-100 p-md md:py-lg md:px-xl overflow-hidden rounded">
        <div className="flex flex-col md:flex-row md:gap-md justify-start md:justify-between items-center">
          <div className="absolute transform sm:-mt-lg sm:scale-75 sm:-translate-y-3xl rotate-200 -translate-y-2xl translate-x-xl md:w-1/2 md:-translate-y-xs md:rotate-3 md:-translate-x-2xl md:scale-105 xl:scale-85 lg:w-1/3 lg:translate-y-0 xl:-translate-y-0 xl:-translate-x-2xl xl:-rotate-12 xl:scale-75 2xl:translate-y-md 2xl:ml-sm 2xl:w-1/4 2xl:scale-90 transition duration-300 ease-out">
            <img
              src="https://gonoodle-assets.s3.amazonaws.com/Universe-Component-Library/general/mcpufferson-waves.png"
              alt="Become a GoNoodle insider by signing up for our weekly newsletter!"
            />
          </div>
          <div className="flex flex-col xl:flex-row mt-3xl md:mt-0 md:ml-3xl xl:ml-2xl gap-sm lg:gap-md xl:gap-lg items-center">
            <div
              className="xl:w-1/2 xl:ml-xl"
              data-testid="newsletter-main-text"
            >
              <div className="gn-headline-lg mb-sm">
                You like us. We like you too. Let&apos;s make this thing
                official.
              </div>
              <p className="gn-text mb-0">
                Become a GoNoodle insider by signing up for our weekly
                newsletter!
              </p>
            </div>

            <div className="relative xl:w-1/2">
              <div className="flex flex-col gap-x-sm">
                <div className="flex gap-x-sm">
                  <div className="basis-2/3 flex-1">
                    <TextField
                      type="email"
                      name="email"
                      aria-label="receive emails from GoNoodle"
                      placeholder="Enter Grownup Email"
                      value={email}
                      onChange={setEmail}
                    />
                  </div>
                  <div className="basis-1/3 hidden lg:block mt-1">
                    <Button
                      variant="dark"
                      size="md"
                      onPress={handleFormSubmit}
                      disabled={!isEmail(email) || !checkboxChecked}
                    >
                      Sign me up!
                    </Button>
                  </div>
                </div>
                <div className="relative flex flex-row items-start py-sm">
                  <input
                    id="tos-checkbox"
                    name="tos-checkbox"
                    type="checkbox"
                    checked={checkboxChecked}
                    onChange={() => setCheckboxChecked(!checkboxChecked)}
                    className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="tos-checkbox"
                    className="ml-sm text-sm"
                    data-testid="gonoodle-tos"
                  >
                    <span className="text-gray-900">
                      I have read and agree to the GoNoodle{" "}
                      <TermsOfServiceLink className="cursor-pointer underline" />
                      ,{" "}
                      <PrivacyPolicyLink className="cursor-pointer underline" />
                      , and{" "}
                      <a
                        href="/company/p/cookie-policy"
                        className="cursor-pointer underline"
                      >
                        Cookie Policy
                      </a>
                      .
                    </span>
                  </label>
                </div>
                <Button
                  variant="dark"
                  size="md"
                  className="block lg:hidden"
                  onPress={handleFormSubmit}
                  disabled={!isEmail(email) || !checkboxChecked}
                >
                  Sign me up!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Newsletter.propTypes = {
  handleSubmit: PropTypes.func,
};

Newsletter.defaultProps = {
  handleSubmit: undefined,
};
