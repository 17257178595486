import React from "react";
import PropTypes from "prop-types";

import { useAssetsConfig } from "../../contexts/AssetsConfig";

import Button from "../Button";
import DateField from "../DateField";
import TermsOfServiceLink from "../TermsOfServiceLink";
import PrivacyPolicyLink from "../PrivacyPolicyLink";

export default function AgeGateBox({
  title,
  description,
  locked,
  autoFocus,
  onLocked,
  onCancel,
  onSuccess,
}) {
  const { assetsUrl } = useAssetsConfig();
  const [age, setAge] = React.useState();
  const [error, setError] = React.useState();

  React.useEffect(() => {
    if (age) {
      setError();
    }
  }, [age]);

  const onSubmit = (e) => {
    if (!age) {
      setError("Please enter your birth date");
    } else if (age < 18 || age > 100) {
      onLocked();
    } else {
      onSuccess(age);
    }
    e.preventDefault();
  };

  return (
    <div className="flex flex-col space-y-md items-center text-center">
      <div className="space-y-sm">
        <h1 className="gn-headline-md">{title}</h1>
        <p className="gn-text" data-testid="age-gate-box-description">
          {description}
        </p>
      </div>
      {locked === false ? (
        <form
          className="flex flex-col space-y-md items-center text-center"
          onSubmit={onSubmit}
          noValidate
        >
          <div className="text-left w-1/2">
            <DateField
              label="Date of Birth"
              required
              errorMessage={error}
              autoFocus={autoFocus}
              onChange={(date) => {
                const isValidDate = date?.year && date?.month && date?.day;
                if (isValidDate) {
                  // interval to duration.
                  const years =
                    new Date(new Date() - date.toDate()).getFullYear() - 1970;
                  setAge(years);
                } else {
                  setAge(undefined);
                }
              }}
            />
          </div>
          <p className="gn-text-sm" data-testid="age-gate-box-description">
            We won&apos;t store this information. Learn more by reading our{" "}
            <TermsOfServiceLink className="cursor-pointer underline" />
            &nbsp;and&nbsp;
            <PrivacyPolicyLink className="cursor-pointer underline" />
          </p>
          <div className="w-full flex space-x-sm xs:space-x-md">
            <Button
              variant="dark-outline"
              size="md"
              onPress={onCancel}
              className="w-full"
            >
              Cancel
            </Button>
            <Button variant="dark" size="md" className="w-full" type="submit">
              Continue
            </Button>
          </div>
        </form>
      ) : null}

      <div
        className={`flex flex-col space-y-md w-full ${!locked ? "hidden" : ""}`}
      >
        <div className="self-center mr-4">
          <img
            className="w-[150px] h-[180px] object-contain"
            width={150}
            height={180}
            src={`${assetsUrl}/web_public/McPufferson-L1-sad.svg`}
            alt=""
          />
        </div>
        <Button
          variant="dark"
          size="md"
          onPress={() => {
            window.location = "/";
          }}
        >
          Watch GoNoodle Videos
        </Button>
      </div>
    </div>
  );
}

AgeGateBox.propTypes = {
  locked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  onLocked: PropTypes.func,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

AgeGateBox.defaultProps = {
  autoFocus: undefined,
  onLocked: () => {},
  onSuccess: () => {},
  onCancel: () => {},
};
