import React from "react";

/**
 * Avoid server and client hydration mismatch
 * by initializing state on client side.
 *
 * Useful for randomizing values.
 */
export default function useClientState(initial, constructor) {
  const [state, setState] = React.useState(initial);

  React.useEffect(() => {
    setState(constructor());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, setState];
}
