export default {
  ANNOUNCEMENT_BANNER: "announcement banner",
  CTA: "cta",
  HERO: "hero",
  SPOTLIGHT: "spotlight",
  SHELF: "shelf",
  RECENTLY_WATCHED_SHELF: "Recently Watched shelf",
  FAVORITES_SHELF: "Favorites Shelf",
  HEADER: "header",
  NAV: "nav",
  FOOTER: "footer",
  PROFILE: "profile",
  CREATE_ACCOUNT: "create account",
  LOGIN: "login",
  FAVORITES: "favorites",
  TRANSMOGRIFIER: "transmogrifier",
  CLEVER: "clever",
  GOOGLE: "google",
  KEYWORD: "keyword",
  KEYWORD_FILTERS: "keyword+filters",
  FILTERS: "filters",
};
