/**
 * Encode URI string
 *
 * @param {String} a string to encode
 * @returns {String} encoded string
 * @example
 * encodeUri("Bought keyword")
 * => "Bought%20keyword"
 */

export default function encodeUri(s) {
  try {
    return encodeURIComponent(s);
  } catch (e) {
    return null;
  }
}
