/**
 * Retrieve the cookies from a request object
 * @param {Object} req - A request object.
 * @returns {String} The cookies from the request headers.
 */
export const getReqCookies = (req) => req && req.headers && req.headers.cookie;

/**
 * Sets cookie headers from the API response on the res and req objects for the Node.js server
 * @param {Object} req - A request object.
 * @param {Object} res - A response object.
 * @returns {(resp: Object) => Object}
 */
export const setCookies = ({ req, res }) => (resp) => {
  const cookieHeader = resp.headers && resp.headers.get("set-cookie");

  if (res && cookieHeader) {
    // Pass set cookie requests from the API through Node.js server to the browser
    res.setHeader("Set-Cookie", cookieHeader);
  }

  if (req && cookieHeader) {
    // Set cookies recevied from the API onto Node.js request object so that
    // chained request have access to the latest cookies
    req.headers.cookie = cookieHeader;
  }

  return resp;
};
