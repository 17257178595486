import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import api from "../api";

const fetcher = api();

function useOnSiteMessagesMutation(options = {}) {
  const queryClient = useQueryClient();

  const {
    mutate: updateMessagesStatus,
    isLoading: isUpdateMessagesMutationLoading,
    isError: updateMessagesMutationError,
  } = useMutation({
    mutationFn: (messagesStatus) =>
      fetcher.updateOnSiteMessageStatus(messagesStatus),
    onMutate: async (messagesStatus) => {
      // Cancel any outgoing refetches
      queryClient.cancelQueries([QUERY_KEYS.USER]);

      // Optimistically update to the new value
      queryClient.setQueryData([QUERY_KEYS.USER], (user) => ({
        ...user,
        onSiteMessages: user.onSiteMessages.map((message) => {
          const foundMessage = messagesStatus.find(
            ({ onSiteMessageId }) =>
              onSiteMessageId === message.onSiteMessageId,
          );
          return foundMessage
            ? {
                ...message,
                status: foundMessage.status,
              }
            : message;
        }),
      }));
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER]);
    },
    ...options,
  });

  return {
    updateMessagesStatus,
    isUpdateMessagesMutationLoading,
    updateMessagesMutationError,
  };
}

export default useOnSiteMessagesMutation;
