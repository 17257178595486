/* eslint-disable react/display-name */
import React from "react";
import { find, isEmpty } from "lodash";

import { useUser } from "../contexts/user";

export default function useCurriculum() {
  const { user } = useUser();

  const curriculum = React.useMemo(() => {
    if (!user?.isLoggedIn) {
      return undefined;
    }

    const classroomWithCurriculum = find(
      user?.classrooms,
      (classroom) =>
        classroom.id === user?.selectedClassroomId &&
        !isEmpty(classroom.curriculumAccesses) &&
        !isEmpty(classroom.curriculums),
    );

    /* 
      Acceses should be prioritized by first element array on the curriculumAcceses get on the curriculum "HARDCODED". 
      Since still there is not a way to provide a user curriculum selection. 
      This was disccused and pre-talked with @jorgegonzalezgn for future inquires 
    */
    return find(
      classroomWithCurriculum?.curriculums?.allCurriculums,
      (curriculumClassroom) =>
        curriculumClassroom.id ===
        classroomWithCurriculum.curriculumAccesses?.[0].curriculums?.[0],
    );
  }, [user?.classrooms, user?.isLoggedIn, user?.selectedClassroomId]);

  return curriculum;
}
