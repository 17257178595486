import React from "react";

export default function useIsFirstInteraction() {
  const [isFirstInteraction, setIsFirstInteraction] = React.useState(true);
  const key = "isFirstInteraction";

  React.useEffect(() => {
    const interacted = sessionStorage.getItem(key);
    if (interacted) {
      setIsFirstInteraction(false);
    } else {
      sessionStorage.setItem(key, true);
    }
  }, []);

  const markAsInteracted = React.useCallback(() => {
    sessionStorage.setItem(key, false);
    setIsFirstInteraction(false);
  }, []);

  return [isFirstInteraction, markAsInteracted];
}
