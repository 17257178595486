import React from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { QUERY_KEYS, USER_TYPES } from "../../constants";
import api from "../../api";
import { useAppConfig } from "../appConfig";
import BugsnagClient from "../../utils/bugsnag";

const client = api();
const missingUserProvider = "You forgot to wrap your app in <UserProvider>";
const USER_SYNC_KEY = "synchronizeUser";

export const UserContext = React.createContext({
  get user() {
    throw new Error(missingUserProvider);
  },
  get error() {
    throw new Error(missingUserProvider);
  },
  get isLoading() {
    throw new Error(missingUserProvider);
  },
});
UserContext.displayName = "UserContext";

export const useUser = () => React.useContext(UserContext);

const UserProvider = ({ children, initialUser }) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { setLogoutToastVisibility } = useAppConfig();

  const { data: user, isLoading: isGettingUser } = useQuery(
    [QUERY_KEYS.USER],
    client.getUser,
    {
      initialData: initialUser,
      refetchOnWindowFocus: true,
      select: (undecoratedUser) => ({
        ...undecoratedUser,
        isAnonymous:
          undecoratedUser.userTypeId === USER_TYPES.ANONYMOUS.id ||
          undecoratedUser.userTypeId === USER_TYPES.ANONYMOUS_UNIVERSE.id,
        isFamily: undecoratedUser.userTypeId === USER_TYPES.FAMILY.id,
        isUnregisteredFamily:
          undecoratedUser.userTypeId === USER_TYPES.UNREGISTERED_FAMILY.id,
        isUnregisteredTeacher:
          undecoratedUser.userTypeId === USER_TYPES.UNREGISTERED_TEACHER.id,
        isClassroomTeacher:
          undecoratedUser.userTypeId === USER_TYPES.CLASSROOM_TEACHER.id,
        isLoggedIn: [
          USER_TYPES.FAMILY.id,
          USER_TYPES.CLASSROOM_TEACHER.id,
        ].includes(undecoratedUser.userTypeId),
      }),
      onSuccess: (undecoratedUser) => {
        BugsnagClient.setUser(undecoratedUser.id, undecoratedUser.email);
      },
    },
  );

  const {
    mutate: updateUser,
    isLoading: isUpdatingUser,
    error: updateError,
  } = useMutation((newUserProperties) => client.updateUser(newUserProperties), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER]);
    },
  });

  const { mutate: updateUserFlags } = useMutation(
    (newUserFlags) => client.updateUserFlags(newUserFlags),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.USER]);
      },
    },
  );

  const handleUserSyncStorageEvent = React.useCallback(
    ({ key, newValue }) => {
      if (key === USER_SYNC_KEY && newValue !== null) {
        // Synchronize user data by rerunning getServerSideProps
        router.replace(router.asPath).then(() => {
          setLogoutToastVisibility(true);
          localStorage.removeItem(USER_SYNC_KEY);
        });
      }
    },
    [setLogoutToastVisibility, router],
  );

  const { mutate: logout, isLoading: isLoggingOut } = useMutation(
    client.logout,
    {
      onSuccess: () =>
        // Creating a new transient user
        queryClient
          .fetchQuery([QUERY_KEYS.USER], client.createUser)
          .then(() => {
            // This will trigger a refresh of the user in the other tabs
            localStorage.setItem(USER_SYNC_KEY, Date.now().toString());

            // This will trigger a refresh of the user in this tab
            handleUserSyncStorageEvent({
              key: USER_SYNC_KEY,
              newValue: Date.now(),
            });
          }),
    },
  );

  const isLoading = [isGettingUser, isUpdatingUser, isLoggingOut].includes(
    true,
  );

  React.useEffect(() => {
    window.addEventListener("storage", handleUserSyncStorageEvent);

    return () => {
      window.removeEventListener("storage", handleUserSyncStorageEvent);
    };
  }, [handleUserSyncStorageEvent]);

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        errors: { updateError },
        updateUser,
        updateUserFlags,
        logout,
      }}
    >
      {user ? children : null}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  initialUser: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

UserProvider.defaultProps = {
  initialUser: undefined,
  children: undefined,
};

export default UserProvider;
