export default ({ get, put, post, checkStatus, toJSON }) => ({
  updateCurriculumSettings: ({ curriculumId, ageGroupId }) =>
    put("universe/v1/classroom_curriculum_settings", {
      body: {
        curriculumId,
        ageGroupId,
      },
    }).then((response) => checkStatus({ response, method: "PUT" })),
  getDeprecatedCurriculum: (id) =>
    get(`universe/v1/curriculums/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/curriculums/:curriculum_id",
        }),
      )
      .then(toJSON),
  getCurriculum: (id) =>
    get(`universe/v2/curriculums/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v2/curriculums/:curriculum_id",
        }),
      )
      .then(toJSON),
  getCompetency: (id) =>
    get(`universe/v1/competencies/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/competencies/:competency_id",
        }),
      )
      .then(toJSON),
  getElement: (id) =>
    get(`universe/v1/elements/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/elements/:element_id",
        }),
      )
      .then(toJSON),
  completeElement: (id) =>
    post(`universe/v1/classroom_element_completions`, {
      body: { elementId: id },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  completeSurvey: ({ ageGroupId, survey }) => {
    const surveyOptionSelections = Object.keys(survey).map((key) => ({
      surveyPromptId: parseInt(key, 10),
      optionId: parseInt(survey[key], 10),
    }));

    return post(`universe/v1/survey_option_selections`, {
      body: {
        ageGroupId,
        surveyOptionSelections,
      },
    }).then((response) => checkStatus({ response, method: "POST" }));
  },
});
